.stores-wrapper {
    .inner {
        padding: 20px;
        margin: 0 auto;
        display: block;
    }
    .stores-body-single {
        /*display: none;*/
    }
    .stores-header {
        .store-option-button {
            &:first-child {
                padding-right: 0;
            }
            &:last-child {
                padding-left: 0;
            }
            background-color: transparent;
            background: none;
            border-width: 0;
            border-color: transparent;
            text-decoration: none;
            h2 {
                border-width: 0px;
                text-decoration: none;
                cursor: pointer;
                transition: 100ms all ease-in;
                .cycle-text-icon {
                    max-height: 35px;
                    max-width: 35px;
                    margin-top: -9px;
                    margin-left: 7px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 15px;
                    .cycle-text-icon {
                        max-height: 19px;
                        margin-left: 2px;
                    }
                }
                @media screen and (max-width: 550px) {
                    font-size: 13px;
                    .cycle-text-icon {
                        max-height: 15px;
                        margin-left: 2px;
                        margin-top: -1px;
                    }
                }
                @media screen and (max-width: 310px) {
                    font-size: 10px;
                    .cycle-text-icon {
                        max-height: 12px;
                        margin-top: -1px;
                    }
                }
            }
            &.active,
            &:hover {
                h2 {
                    border-top-width: 0px;
                    /*color: #c51017;*/
                    background-color: #111;
                    text-decoration: none;
                }
            }
            &.active {
                h2 {
                    background-color: $theme_color_darken;
                    background-color: rgb(235, 81, 153);
                }
            }
        }
    }
}