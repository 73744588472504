#CartContainer {
    .cart-href {
        display: none !important;
        visibility: hidden !important;
    }
}

body .wrapper #main_navbar {
    height: 0px !important;
}

h2.menu-cat-header.prodgroup-title {
    display: block;
    text-align: left;
    background-color: #000000;
    color: #fff;
    padding: 7.5px 25px;
    margin: 0;

    @media screen and (max-width: 767px) {
        font-size: 18px;
    }
}

.desktop-categories-placeholder {
    /*max-height: 300px !important;*/
    /*overflow: hidden;*/
}

.order-wrapper {
    margin-top: 15px;
}

@media screen and (min-width: 992px) and (max-width: 1299px) {
    .prod-grid {
        >div {
            float: none;
            margin: 0 auto;
        }
    }
}

.header {
    @media screen and (max-width: 991px) {
        .logo {
            display: block;
            position: absolute;
            padding: 8.5px;
            max-height: 50px;
            max-width: 150px;
            position: absolute;

            img {
                max-width: 100%;
                width: auto;
            }
        }
    }

    @media screen and (min-width: 1300px) {
        .navbar .nav-cart .nav-cart-container {
            top: 100px;
        }
    }

    #navbar {
        border-width: 0px !important;

        .nav-cart {
            &.cart-active {
                .cart-href {

                    /*background-color: $black_1;*/
                    /*color: $white_1;*/
                    .order-info {
                        /*color: $white_1;*/
                    }

                    .order-cart {
                        /*color: $white_1;*/
                    }
                }
            }

            .nav-cart-container {
                /*background-color: $black_1;*/
                /*color: $white_1;*/
            }

            @media screen and (max-width: 991px) {
                .cart-href {
                    .order-cart {
                        display: block;
                        position: absolute;
                        width: 55px;
                        height: 100%;
                        line-height: 50px;
                        font-size: 2em;
                        text-align: center;
                        color: #fff;
                        right: 5px;
                    }

                    .order-info {
                        display: flex;
                        float: right;
                        width: 120px;
                        height: 100%;
                        font-size: 15px;
                        flex-wrap: wrap;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0px 10px;
                        color: #fff;
                        margin-left: 50px;
                        text-align: right;
                        margin-right: 50px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .header-store-info {
        h1 {
            font-size: 30px;
        }

        h2 {
            font-size: 26px;
        }
    }
}

body {
    .open-side-nav-mobile {
        display: none;

        @media screen and (max-width: 991px) {
            position: absolute;
            display: block;
            margin-left: 8.5px;
            margin-top: 8.5px;
        }
    }

    .header {
        .header-store-info {
            position: absolute;
            bottom: 30px;
            padding-bottom: 7.5px;
            padding-top: 7.5px;

            h1,
            h2 {
                margin: 0;
            }

            h1 {
                font-size: 22px;
                margin-bottom: 5px;
            }

            h2 {
                font-size: 16px;
            }
        }

        #main_navbar.navbar-default .sub-menu {
            @media screen and (max-width: 991px) {
                border: none;
                position: absolute;
                top: 0px;
                /*left: 0;*/
                right: 70px;
                width: 180px;
                max-width: none;
                display: block;
                padding-right: 0px;
                padding-left: 0px;

                .nav-cart {
                    background-color: rgba(31, 31, 31, 0.6);
                    max-width: 180px;
                    float: right;
                    border: none;
                    margin: 0;
                    padding: 0;

                    .cart-href {
                        width: 100%;
                        border-right: 1px solid rgba(255, 255, 255, 0.3);
                        margin-left: 0;
                        margin-right: 0;
                        max-width: none;
                    }
                }
            }
        }
    }

    .header {
        .navbar {
            #CartContainer.nav-cart {
                .nav-cart-container {
                    .list-layout-title {
                        display: none;
                    }

                    @media screen and (min-width: 751px) {
                        width: calc(1465px * 0.25);

                        div.cart-inner {
                            /*overflow-y: inherit;*/
                        }

                        .prod_count {
                            max-width: none;
                            min-width: 40px;
                        }
                    }

                    .list-layout-title {
                        display: block;
                        padding-left: 15px;
                    }
                }
            }
        }

        .price-total-number {
            white-space: nowrap;
        }
    }
}

.ripple-effect {
    background-color: $theme_color !important;
}

@media screen and (min-width: 992px) {
    .nav-categories {
        /*position: relative !important;
        top: 0 !important;*/
    }
}

.helperArrow-container {
    position: fixed;
    display: block;
    background-color: transparent;
    left: 0;
    bottom: 0;
    right: 0;
    height: 60px;

    @media screen and (min-width: 330px) {
        display: none !important;
    }

    .helperArrow {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 5px;
        display: block;
        width: 40px;
        height: 40px;
        /*background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI0MC44MTEgMjQwLjgxMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQwLjgxMSAyNDAuODExOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCI+CjxnPgoJPHBhdGggaWQ9IkV4cGFuZF9Nb3JlIiBkPSJNMjIwLjA4OCw1Ny42NjdsLTk5LjY3MSw5OS42OTVMMjAuNzQ2LDU3LjY1NWMtNC43NTItNC43NTItMTIuNDM5LTQuNzUyLTE3LjE5MSwwICAgYy00Ljc0LDQuNzUyLTQuNzQsMTIuNDUxLDAsMTcuMjAzbDEwOC4yNjEsMTA4LjI5N2wwLDBsMCwwYzQuNzQsNC43NTIsMTIuNDM5LDQuNzUyLDE3LjE3OSwwTDIzNy4yNTYsNzQuODU5ICAgYzQuNzQtNC43NTIsNC43NC0xMi40NjMsMC0xNy4yMTVDMjMyLjUyOCw1Mi45MTUsMjI0LjgyOCw1Mi45MTUsMjIwLjA4OCw1Ny42Njd6IiBmaWxsPSIjRDgwMDI3Ii8+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);*/
        background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im0xMjEuMywzNC42Yy0xLjYtMS42LTQuMi0xLjYtNS44LDBsLTUxLDUxLjEtNTEuMS01MS4xYy0xLjYtMS42LTQuMi0xLjYtNS44LDAtMS42LDEuNi0xLjYsNC4yIDAsNS44bDUzLjksNTMuOWMwLjgsMC44IDEuOCwxLjIgMi45LDEuMiAxLDAgMi4xLTAuNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIgMC4xLTUuOHoiIGZpbGw9IiNGRkZGRkYiLz4KICA8L2c+Cjwvc3ZnPgo=);
        background-size: 30px;
        margin: 0 auto;
        background-color: rgba(10, 10, 10, 0.9);
        border-radius: 0;
        background-repeat: no-repeat;
        cursor: pointer;
        background-position: center;
    }
}

button.btn.prod_delete {
    height: 40px;
    width: 40px;
    text-align: center;
    min-height: 40px !important;
    margin-left: -10px;
    margin-top: -5px;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

@keyframes bounceLeft {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translate(0px, -50%);
    }

    40% {
        transform: translate(-5px, -50%);
    }

    60% {
        transform: translate(-2.5px, -50%);
    }
}

body.page-bestel {
    .wrapper {
        .slick-initialized {
            .slick-next {
                animation: bounceLeft 2s forwards;
            }
        }

        .slick-list.draggable {
            &:after {
                z-index: 1;
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 20px;
                background-color: #333333;
                background: -moz-linear-gradient(left, rgba(51, 51, 51, 1) 0%, rgba(51, 51, 51, 0.01) 100%);
                background: -webkit-linear-gradient(left, rgba(51, 51, 51, 1) 0%, rgba(51, 51, 51, 0.01) 100%);
                background: linear-gradient(to right, rgba(51, 51, 51, 1) 0%, rgba(51, 51, 51, 0.01) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333333', endColorstr='#00333333', GradientType=1);
            }

            &:before {
                z-index: 1;
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 20px;
                background-color: #333333;
                background: -moz-linear-gradient(left, rgba(51, 51, 51, 0.01) 0%, rgba(51, 51, 51, 1) 100%);
                background: -webkit-linear-gradient(left, rgba(51, 51, 51, 0.01) 0%, rgba(51, 51, 51, 1) 100%);
                background: linear-gradient(to right, rgba(51, 51, 51, 0.01) 0%, rgba(51, 51, 51, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00333333', endColorstr='#333333', GradientType=1);
            }
        }
    }
}

.bounce {
    animation: bounce 2s infinite;
}

.toppings {
    width: 100% !important;
}

.order-wrapper .block-layout .product-tab {
    display: block;
}

.nav-categories .nav-categories-list li.menu-list-item>a.active {
    color: #eb5199;
}

@media screen and (max-width: 991px) {
    .bestel-header-store-info {
        margin-top: 134px;
    }

    .mobile-categories-horizontal {
        min-height: 84px;

        .sh-relative {
            min-height: 84px;
        }
    }
}

@media screen and (max-width: 722px) {
    .bestel-header-store-info {
        margin-top: 148px;
    }

    .mobile-categories-horizontal {
        min-height: 98px;

        .sh-relative {
            min-height: 98px;
        }
    }
}

body div.wrapper>div.order-wrapper .block-layout .prod-grid .block-prod-container {
    position: relative;

    img {
        margin: 0 auto;
    }

    .toppings {
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        width: 100%;

        .toppings-inner {
            .topping-flex {
                .Addon {
                    flex-basis: 100%;
                    width: 100%;

                    label,
                    i {
                        color: #000;
                    }
                }
            }
        }
    }

    .toppings-inner {
        background-color: rgba(0, 0, 0, 0.6);
        border: 0px solid transparent;
    }

    .adding-prods {
        width: 100%;
        clear: both;
        display: block;
        margin-bottom: 10px;
    }

    .add-prod {
        width: 100%;
        clear: both;
        display: block;
    }

    .add-prod-container {
        background-color: rgba(0, 0, 0, 0.6);
        display: block;
    }

    .has-error .form-control {
        border-color: transparent;
        border-width: 0px;
        border: none;
        box-shadow: 0px 0px 10px 5px rgba(255, 0, 0, 1);
    }

    //for product list on small devices
    @media screen and (max-width: 991px) {
        margin-bottom: 0px;
        padding-bottom: 0px;

        .block-product {
            display: flex;
            flex-wrap: wrap;

            a.cover-img {
                width: 30%;
                padding: 7.5px;
            }

            .product-info {
                width: 70%;

                .product-description {
                    margin-bottom: inherit !important;
                }
            }

            .add-product {
                &.block-product-cta {
                    width: 100%;
                    position: relative !important;

                    >.price {
                        padding: 5px;
                        min-height: 30px;
                    }

                    >.add {
                        line-height: 30px;
                        font-size: 23px;
                        width: 30px;
                    }
                }
            }
        }
    }
}

//for product list on small devices
@media screen and (max-width: 991px) {
    body .wrapper .order-wrapper .block-layout .prod-grid>div .product-list .product-tab .products-wrapper>div {
        margin-bottom: 10px;
    }
}

.select-topping label {
    color: #333;
    font-weight: lighter;
}

.add-prod-container .count-prod-icon {
    color: #333;
}

.mobile-categories-horizontal {
    .nav-categories-list {
        /*overflow-x: auto;*/
        -webkit-overflow-scrolling: touch;
        max-width: 100%;

        >li {
            min-width: 22%;

            @media screen and (min-width: 700px) {
                min-width: 13.1%;
            }
        }
    }

    @media screen and (min-width: 992px) {
        display: none !important;
    }

    @media screen and (max-width: 991px) {
        margin-top: 50px;
    }
}

@media screen and (min-width: 992px) {
    .header .navbar .nav-cart .nav-cart-container {
        transition: 150ms all ease-in;
        height: 0;
        position: absolute;
        overflow-y: auto;
        z-index: -1;
        width: 400px;
        width: calc(1465px * 0.3);
        display: block;
        top: 50px;
        right: -15px;
        background-color: #fff;
        box-shadow: 0px 0px 2px 2px transparent;
        /* overflow: scroll; */
    }
}

@media screen and (max-width: 767px) {
    body {
        .store-select-wrapper .stores-wrapper .stores-body {
            background-color: #ef5ea2 !important;
        }

        .order-choise .store-list .store-list-conainer .store-info {
            padding: 10px;
        }
        .order-choise .store-list .store-list-conainer .store-info .info-list h3{
            margin-bottom: 5px;
            margin-top: 5px
        }
        .order-choise .store-list .store-list-conainer .store-info .info-list span{
            font-size: 12px;
        }
        .order-choise .store-list .store-list-conainer .store-info .info-list .info-line{
            padding-top: 0;
            padding-bottom: 0;
        }
        .order-choise .store-list .store-list-conainer .store-info .info-list .spacer-dotted {
            margin-top: 1.5px;
            margin-bottom: 0px;
        }
    }
}