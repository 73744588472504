.header {
    &.add-slider {
        .slick-slider {
            .slick-nav {
                color: rgba(180, 180, 180, 0.8);
            }
            .slider-image {
                height: 250px;
            }
        }
    }
}

.cart-hide-xs {
    display: block;
    @media screen and (max-width: 991px){
        display: none !important;
    }
}

.header.add-slider .slick-nav{
    @media screen and (max-width: 767px){
        font-size: 3em;
        transform: translateY(-62.5%);
    }
}

.nav-cart-display-menu {
    margin-right: -15px;
    display: block;
    max-width: 30%;
    float: left;
    i,
    span {
        color: #fff;
    }
    @media screen and (max-width:991px){
        max-width: 17%;
        margin-right: 20px;
    }
    @media screen and (max-width:768px){
        max-width: 25%;
        margin-right: 20px;
    }
    @media screen and (max-width:450px){
        max-width: 30%;
        margin-right: 20px;
    }
    .order-info {
        max-width: 60%;
        display: block;
        width: 60%;
        padding: 4.5px;
        float: left;
        @media screen and (max-width: 500px){
            font-size: 11px;
            padding-top: 9px;
        }
    }
    .order-cart {
        max-width: 40%;
        display: block;
        width: 40%;
        float: left;
        text-align: right;
        i {
            font-size: 30px;
            line-height: 46px;
            @media screen and (max-width: 991px){
                font-size: 30px;
            }
            @media screen and (max-width: 500px){
                font-size: 25px;
            }
        }
    }
    @media screen and (min-width: 992px){
        display: none !important;
    }
}

.open-side-nav-mobile {
    display: none !important;
}

.header-container {
    background-color: $black_1;
    display: block;
    height: 60px;
    width: 100%;
    position: relative;
    z-index: 6;
    .logo {
        max-height: 99px;
        max-width: 175px;
        display: block;
        margin: 0 auto;
        float: none;
        position: relative;
        img {
            max-width: 100%;
            max-height: 100%;
            top: 0px;
            display: block;
        }
    }
    @media screen and (max-width: 991px) {
        display: none;
    }
}

.main-navbar-top {
    background-color: #000;
    .navbar-header {
        // width: 100%;
        // display: block;
    }
    .navbar-nav {
        > li {
            >a {
                font-family: 'Roboto Slab', serif;
                color: #fff;
                font-size: 15px;
                line-height: 19px;
                &:hover {
                    color: #eb5199;
                }
            }
        }
    }
    .centered-nav-logo {
        position: absolute;
        width: 160px;
        max-height: 160px;
        margin: 0 auto;
        left: 0;
        right: 0;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            @media screen and (max-width: 768px) {
                max-width: 120px;
                display: inherit;
            }
        }
    }
}