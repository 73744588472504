.sh-bg {
    &.sh-relative {
        position: relative;
    }
    h1 {
        font-size: 30px;
    }
    &:before {
        content: '';
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    &.sh-purple {
        &:before {
            background-color: $theme_sec_color;
        }
    }
    &.sh-darkgrey {
        &:before {
            background-color: $darkgrey;
        }
    }
    &.sh-grey {
        &:before {
            background-color: $grey;
        }
    }
    &.sh-pattern {
        &:before {
            display: none;
        }
        &:after {
            height: 100%;
            background-image: url(./images/bg-pattern.png);
            background-size: auto;
            width: 100%;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            background-repeat: no-repeat;
            background-position: 360px -270px;
        }
    }
}

.navbar-brand.logo {
    >img {
        max-width: 175px;
        max-height: 99px;
        margin-top: -17.5px;
        margin-left: -15px;
        max-height: 80px;
        @media screen and (max-width: 767px) {
            max-height: 55px;
        }
    }
}

.main-menu {
    .navbar-nav {
        /*margin: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        min-height: 100%;*/
        margin: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        min-height: 100%;
        display: table-cell;
        vertical-align: middle;
        height: 100%;
        height: 100vh;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 100vw;
        float: none;
        justify-content: center;
        align-items: center;
    }
    li {
        text-align: center;
        font-weight: lighter;
        font-size: 1.4em;
        a {
            color: #f5f5f5;
            padding: 20px;
            border-bottom: none; // width: 300px;
            margin: 0 auto;
            max-width: 100%;
        }
        &:last-child {
            a {
                border-bottom: none;
            }
        }
        &.divider {
            padding: 0px;
            max-width: 100%;
            width: 200px;
            margin: 0 auto;
            background-color: $darkgrey;
        }
    }
}

@media screen and (max-width: 991px) {
    #navbar_mainmenu {
        .navbar-nav {
            >li {
                >a {
                    border-bottom: none;
                    display: block;
                    position: relative;
                    font-family: Roboto Slab;
                    font-size: 20px;
                    &:after {
                        content: '';
                        display: block;
                        width: 200px;
                        margin: 0 auto;
                        height: 1px;
                        background-color: rgba(255, 255, 255, 0.2);
                        left: 0;
                        right: 0;
                        bottom: 0;
                        position: absolute;
                    }
                }
                &:last-child {
                    >a {
                        &:after {
                            display: none;
                        }
                    }
                }
                >ul {
                    >li {
                        >a {
                            border-bottom: none;
                            font-family: Roboto Slab;
                            display: block;
                            position: relative;
                            font-weight: lighter;
                            font-size: 16px;
                            color: rgba(255, 255, 255, 0.7);
                            &:after {
                                content: '';
                                display: block;
                                width: 200px;
                                margin: 0 auto;
                                height: 1px;
                                background-color: rgba(255, 255, 255, 0.1);
                                left: 0;
                                right: 0;
                                bottom: 0;
                                position: absolute;
                            }
                        }
                        &:last-child {
                            >a {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.overlay {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 1090;
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.95);
    overflow-x: hidden;
    transition: 0.5s;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    max-height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.overlay {
    .closebtn {
        position: absolute;
        top: 20px;
        right: 45px;
        font-size: 60px;
        color: $white;
        text-decoration: none;
        z-index: 10;
        &:hover,
        &:focus,
        &:active {}
    }
    &[style="height: 100%;"]{
        .closebtn{
            position: fixed;
        }
    }
}

.title-header {
    display: block;
    width: 100%;
    padding: 15px 15px;
    text-transform: uppercase;
    text-align: center;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
    }
    h3 {
        font-size: 20px;
    }
    &.purple {
        background-color: $theme_sec_color;
        color: $white;
    }
}

.no-margin-top {
    margin-top: 0px;
}

.no-min-height {
    min-height: 0px;
}

.theme-line {
    height: 2px;
    width: 100%;
    display: block;
    background-color: $theme_color;
    clear: both;
    margin-bottom: 10px;
}

.theme-color {
    color: $theme_color;
}

.key_image {
    height: 75px;
    width: 75px;
    display: block;
    clear: both;
}

.cta {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    font-family: 'Roboto Slab', serif;
    font-size: 1.4em;
    border-radius: 2px;
    display: block;
    max-width: 350px;
    padding: 10px 12px;
    margin: 0 auto;
    line-height: 30px;
    img {
        max-width: 100%;
        height: 10px;
        width: 10px;
        display: inline;
        height: 30px;
        width: 30px;
        position: absolute;
        margin-left: 10px;
    }
    &.small {
        font-size: 1em;
        padding: 5px 12px;
        line-height: inherit;
        img {
            top: 9.5px;
        }
    }
}

@media screen and (max-width: 767px) {
    .sh-bg {
        h1,
        h2,
        h3 {
            font-size: 16px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    .title-header {
        h1,
        h2 {
            font-size: 16px;
            margin-top: 2.5px;
            margin-bottom: 2.5px;
        }
    }
}

@media screen and (max-width: 991px) {
    .cart-href-extra {
        display: block;
        width: 100%;
    }
    .btn {
        min-height: 50px !important;
        line-height: 36px;
    }
    .cta.small {
        font-size: 1.5em; // line-height: 50px;
        img {
            top: 18.5px;
        }
        @media screen and (max-width: 767px) {
            display: block;
            width: 100%;
            line-height: 38px;
        }
    }
}

#navbar_mainmenu {
    .nav {
        >li {
            >a {
                &:hover,
                &:focus,
                &:visited {
                    background-color: transparent !important;
                }
            }
        }
    }
    .navbar-nav .open .dropdown-menu>li>a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 10px 15px 10px 25px;
    }
    .nav .open>a,
    .nav .open>a:hover,
    .nav .open>a:focus {
        background-color: transparent !important;
    }
}

a.rating-link {
    color: orange;
}

.uppercase {
    text-transform: uppercase;
}

.text-white {
    color: $white;
}

.btn-success {
    background-color: $theme_color !important;
    border-color: darken($theme_color, 2.5%) !important;
    &:hover,
    &:active,
    &:focus {
        background-color: darken($theme_color, 10%) !important;
        border-color: darken($theme_color, 12.5%) !important;
    }
}

.btn-primary {
    background-color: $theme_color !important;
    border-color: darken($theme_color, 2.5%) !important;
    &:hover,
    &:active,
    &:focus {
        background-color: darken($theme_color, 10%) !important;
        border-color: darken($theme_color, 12.5%) !important;
    }
}

.postcode-row {
    .postcode-container {
        .postcode-header {
            h2 {
                background-color: $black_2;
            }
        }
        .postcode-body {
            background-color: $black_3;
        }
    }
}

.img-fluid {
    width: 100%;
}

.shop-next {
    display: none;
    @media screen and (max-width: 1299px) {
        display: block;
    }
}

.link-white-normal {
    color: #fff;
    text-decoration: underline;
    &:hover {
        color: #ED1B23;
    }
}

form#postcodeform .CheckZipCode {
    height: 50px;
    min-width: 150px;
    @media screen and (max-width: 767px) {
        min-width: 50px;
    }
}

.store-select-wrapper {
    .postcode-row {
        .postcode-container {
            .postcode-header {
                h2 {
                    background-color: #202020;
                }
            }
            .postcode-body {
                background-color: #303030;
            }
        }
    }
    #post_code {
        text-align: center;
        padding: 25px;
        text-align: left;
        border-radius: 0px;
        border: 0px;
    }
    .stores-wrapper {
        max-width: 1150px;
        display: block;
        margin: 0 auto;
        .stores-header {
            background-color: $darkgrey;
            h2 {
                font-size: 1.4em;
                margin: 0;
                padding: 0;
                padding-bottom: 11px;
                text-align: center;
                display: block;
                padding-top: 15px;
                color: #fffff4;
            }
        }
        .stores-body {
            background-color: $grey;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .search_input {
                max-width: 100%;
                width: 300px;
                text-align: center;
                display: block;
                margin: 0 auto;
                border-width: 0px;
                float: none;
                border-radius: 0px;
            }
            .search_input::placeholder {
                /*color: #333;*/
            }
            .store-list {
                .store-list-conainer {
                    .store-info {
                        border-radius: 4px;
                        .info-title {
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.show-mobile {
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
    }
}

.add-slider {
    @media screen and (max-width: 767px) {
        margin-top: 50px;
    }
}

.slick-slider {
    .info {
        text-align: left !important;
        max-width: 800px;
        display: block;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
    .slider-heading {
        background-color: #e21a78;
        color: #fff;
        text-shadow: none !important;
        display: inline;
        padding: 5px 20px;
        margin-top: 0px;
        min-width: 20px;
        display: block;
        max-width: 400px;
        margin-bottom: 0px;
        @media screen and (max-width: 767px) {
            background-color: rgba(226, 26, 120, 0.59);
        }
    }
    .slider-subheading {
        margin-top: 0px;
        margin-bottom: 10px;
        color: #fff;
        background-color: rgba(0, 0, 0, 1);
        clear: both;
        display: inline;
        padding: 2.5px 30px;
        display: block;
        margin-top: 0px;
        max-width: 500px;
        @media screen and (max-width: 767px) {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
    .btn {
        font-weight: bold;
        background-color: rgba(224, 32, 122, 0.58);
    }
}

.content-image-full-width-mobile {
    @media screen and (max-width: 676px) {
        margin-left: -30px;
        max-width: calc(100% + 60px);
    }
}

.navbar-toggle {
    border-radius: 0px;
}

.shabu-bg-list {
    margin-top: 30px;
    margin-bottom: 30px;
    display: block;
    background-image: url('../images/sushi-foto.jpg');
    background-size: cover;
    background-position: center;
    .only-takeaway {
        max-width: 120px;
        margin-right: 15px;
        margin-top: -3px;
        @media screen and (max-width: 991px) {
            float: inherit !important;
            margin-top: 10px;
        }
    }
    @media screen and (max-width: 991px) {}
    h2 {
        padding: 15px 15px;
        margin: 0px;
        display: block;
        background-color: rgb(235, 81, 153);
        color: #fff;
        font-size: 1.4em;
    }
    .footer-stores {
        display: block;
        list-style-type: none;
        padding: 0;
        margin: 0;
        background-color: transparent;
        >li {
            background-color: transparent;
            padding: 0;
            margin: 0;
            border-bottom: 1px solid #fff;
            h3 {
                padding: 0;
                margin: 0;
                a {
                    padding: 15px 15px;
                    ;
                    display: block;
                    width: 100%;
                    font-size: .8em;
                    font-family: 'Roboto Slab', serif;
                    text-transform: inherit;
                    color: #000;
                    strong {
                        text-transform: inherit;
                        font-family: 'Open Sans', sans-serif;
                    }
                }
                @media screen and (max-width: 991px) {
                    font-size: 15px;
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.actie-prod-contact-seo {
    display: block !important;
}