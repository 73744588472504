/*SCROLL*/

/*::-webkit-scrollbar {
    max-width: 5px;
    background: #fff;
    background-color: #fff;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0);
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-color: 1px solid rgba(229, 229, 229, 0.9);
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px;
    border-radius: 50px;
    background: rgba(51, 51, 51, 0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(51, 51, 51, 0.2);
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(51, 51, 51, 0.6);
}*/

@media screen and (min-width: 991px){
    #navbar .nav-cart .cart-href .order-info {
        font-size: 13px;
    }
}

@keyframes fa-blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
        color: $theme_color;
    }
    100% {
        opacity: 1;
    }
}

/*.navbar-collapse {
    max-height: 0vh;
    transition: 500ms all ease-in;
    &.in {
        height: 100vh;
        background-color: #000;
        max-height: 100vh;
    }
}*/

body {
    overflow-y: scroll;
    background-color: $body_background;
    /*background-image: url('https://www.sushipoint.nl/styles/images/sushipoint_glow.png');*/
    background-size: cover;
    background-attachment: fixed;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Roboto Slab', serif;
    }
    .wrapper {
        width: $site_width;
        max-width: 100%;
        box-shadow: none;
        background-color: $body_background;
        .content-container {
            .content-extrabig {
                p {
                    font-size: 1.15em;
                }
            }
        }
        .site_inner_witdh {
            max-width: 100%;
            width: $site_inner_witdh;
        }
        .order-wrapper {
            /*min-height: 100vh;*/
            .block-layout {
                width: 100%;
                /*overflow-x: hidden;*/
                display: block;
                max-width: 100%;
                height: 100%;
                position: relative;
                .prod-grid {
                    transition: 200ms all ease-in;
                    height: 100%;
                    .btn-spinner.add-product::after {
                        top: 30%;
                        transform: translateY(-50%);
                    }
                    >div {
                        height: 100%;
                        .product-list {
                            position: relative;
                            height: 100%;
                            width: 100%;
                            max-width: 100%;
                            overflow-x: hidden;
                            .product-tab {
                                .tab-inner {
                                    overflow: hidden;
                                    max-height: 100%;
                                    width: 100%;
                                    background-color: #f5f5f5;
                                    margin-bottom: 30px;
                                }
                                &.tab-active {
                                    height: 100%;
                                    opacity: 1;
                                    width: 100%;
                                }
                                .products-wrapper {
                                    >div {
                                        margin-bottom: 30px;
                                    }
                                    display: flex;
                                    flex-wrap: wrap;
                                    width: 100%;
                                    @media screen and (min-width: 751px) {
                                        /*max-width: 700px;*/
                                        /*margin: 0 auto;*/
                                    }
                                }
                                @media screen and (min-width: 1000px) and (max-width: 1299px) {
                                    .prod-col {
                                        width: 33.332%;
                                        width: 50%;
                                    }
                                }
                                .inner {
                                    display: block;
                                    padding: 0px;
                                    height: 100%;
                                    .block-prod-container {
                                        height: 100%;
                                        .block-product {
                                            position: relative;
                                            height: 100%;
                                            border: 1px solid #ddd;
                                            /*background-color: $black_2;*/
                                            max-width: 312.5px;
                                            @media screen and (max-width: 767px){
                                                max-width: 100%;
                                            }
                                            margin: 0 auto;
                                            .cover-img {
                                                /*background-color: #080001;*/
                                                /*background-image: url('https://www.sushishop.nl/product-6105-400x400/rol-gravlax-zalm.png') !important;*/
                                                // height: 150px;
                                            }
                                            .product-description {
                                                max-height: none !important;
                                                min-height: 0px !important;
                                                overflow: auto;
                                                font-style: italic;
                                                color: #999;
                                                margin-bottom: 40px;
                                            }
                                            .block-product-cta {
                                                position: absolute;
                                                left: 0;
                                                right: 0;
                                                bottom: 0;
                                                border-radius: 0px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .product-info {
                    /*background-color: $black_2;*/
                    .product-description {
                        max-height: none !important;
                        min-height: 0px !important;
                        overflow: auto;
                    }
                }
            }
            .categorie-container {
                li {
                    .categorie-item.active {
                        .categorie-title {
                            color: $white_1;
                            background-color: rgba( $theme_color, .8);
                        }
                    }
                }
            }
            .list-layout {
                .product-list .list-item {
                    background-color: $black_3;
                    &.prod-item .prod-inner .prod-info .prod-image-container .prod-image-inner {
                        background-color: $black_3;
                    }
                    &:hover {
                        background-color: $black_4;
                        &.prod-item .prod-inner .prod-info .prod-image-container .prod-image-inner {
                            background-color: $black_4;
                        }
                    }
                }
            }
        }
        .header.small {
            /*padding-top: 45px;*/
            /*height: 195px;*/
            /*padding-top: 72.5px;*/
            height: 300px;
            margin-top: 50px;
            height: 35vh;
        }
        .header.bestel {
            background-image: url('/Content/images/black.png');
            background-image: url('/content/images/header.png');
            transition: background 400ms ease-in;
            @media screen and (max-width: 991px) {
                height: 350px;
                background-position: top;
                height: 35vh;
            }
            @media screen and (max-width: 767px) {
                height: 300px;
                background-position: top;
                height: 35vh;
            }
        }
        #main_navbar {
            width: $site_width;
            max-width: 100%;
            ul {
                a {
                    font-family: 'Roboto Slab', serif;
                }
            }
            @media screen and (min-width: 992px) {
                height: 30px;
                min-height: 0px;
            }
            .dropdown-menu {
                background-color: $black_1;
                background-color: rgba(16, 16, 16, 0.85);
                li {
                    a {
                        color: $white_1;
                    }
                    &:hover {
                        a {
                            /*background-color: $theme_color;*/
                            /*color: $black_1;*/
                            color: $theme_color;
                            background-color: transparent;
                        }
                    }
                }
            }
            #navbar {
                .nav-cart {
                    >a {
                        &:hover {
                            .order-info {
                                color: #fff;
                            }
                        }
                    }
                    .order-info {
                        white-space: nowrap;
                        color: $white_1;
                    }
                    .cart-inner {
                        .error-line {
                            background-color: #333;
                        }
                        .cart-line {
                            border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
                        }
                    }
                    .price-total-container {
                        border-bottom: 2px solid rgba(255, 255, 255, 0.3);
                    }
                    .cart-href {
                        box-shadow: none !important;
                        &:hover {
                            background-color: transparent;
                            .order-cart {
                                color: #fff;
                            }
                        }
                    }
                    .cart-chevron-turn i {
                        transition: 100ms all ease-in;
                        font-size: .8em;
                        transform: rotate(0deg);
                    }
                }
                .nav-cart.cart-active {
                    .nav-cart-container {
                        box-shadow: none;
                        height: calc(100vh - 50px);
                        @media screen and (min-width: 992px) and (max-width: 1300px) {
                            height: calc(100vh - 400px);
                            height: calc(100vh - 200px);
                        }
                        @media screen and (max-width: 1300px) {
                            top: 30px;
                        }
                        @media screen and (max-width: 992px) {
                            top: 50px;
                        }
                        @media screen and (max-width: 750px) {
                            top: 50px;
                        }
                    }
                    .cart-chevron-turn i {
                        transform: rotate(90deg);
                    }
                    .cart-href {
                        /*width: 165px;*/
                        /*background-color: $body_background;*/
                        border-left: 2px solid rgba(235, 235, 235, 0.3);
                        white-space: nowrap;
                        @media screen and (max-width: 991px) {
                            border-left-width: 0px !important;
                            background-color: transparent;
                            background-color: rgba(32, 32, 32, 0.8);
                            border-radius: 0px;
                        }
                    }
                    .order-info {
                        white-space: nowrap;
                        color: $white_1;
                    }
                    .order-cart {
                        color: $white_1;
                    }
                }
                @media screen and (min-width: 992px) {
                    .spinner:not(.spinner-line-item) {
                        margin: 6px 43.5px;
                        height: 17.5px;
                        width: 17.5px;
                        border: 2px solid #fff;
                        border-right-color: transparent;
                    }
                    #navbar_mainmenu {
                        min-height: 0px;
                        height: 30px;
                        float: left;
                        >ul {
                            >li {
                                >a {
                                    text-transform: uppercase;
                                    padding-top: 8px;
                                    padding-bottom: 6px;
                                    font-size: 16px;
                                    font-weight: normal;
                                    border-width: 0px;
                                    line-height: 1;
                                }
                                &:hover {
                                    border-width: 0px;
                                    >a {
                                        color: $theme_color;
                                    }
                                }
                            }
                        }
                    }
                    .nav-cart {
                        .cart-href {
                            height: 30px;
                            width: 125px;
                            .order-top-info {
                                display: none;
                            }
                            .order-cart {
                                font-size: 1em;
                                line-height: 30px;
                                width: auto;
                            }
                        }
                        .nav-cart-container {
                            top: 30px;
                        }
                    }
                }
            }
        }
        .header {
            .navbar {
                .nav-cart {
                    .nav-cart-container {
                        /*background-color: #202020;*/
                        /*color: $white_1;*/
                    }
                }
            }
        }
        .footer {
            background-color: $black_2;
            position: relative;
            z-index: 5;
            @media screen and (max-width: 991px) {
                z-index: 4;
            }
        }
    }
    .switchinverse {
        visibility: hidden !important;
        display: none !important;
    }
}

body {
    .mobile-menu-button-container {
        display: none;
        position: fixed;
        top: 51px;
        width: 100%;
        padding: 10px;
        padding-left: 20px;
        background-color: rgba(0, 0, 0, 0.4);
        .open-side-nav-mobile {}
        @media screen and (max-width: 991px) {
            display: block;
        }
    }
    .mobile-side-nav {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch; //ios scroll momentum
        display: none;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background-color: #000;
        max-height: 100%;
        overflow-y: auto;
        transform: translateX(-100%);
        transition: 500ms all ease-in;
        .mobile-side-header {
            height: 500px;
            height: 35vh;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-image: url('/content/images/header.png');
            position: relative;
            box-shadow: inset 0px -60px 15px -40px rgba(0, 0, 0, 0.6);
            &:before {
                content: '';
                height: 100%;
                width: 100%;
                display: block;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
            }
            .logo-container {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin: 0 auto;
                display: block;
                padding-left: 20px;
                max-width: 100%;
                >img {
                    display: block;
                    max-width: 100%;
                }
            }
            .close-side-nav-mobile {
                position: absolute;
                bottom: 25px;
                right: 25px;
            }
        }
        .nav-categories-list {
            padding: 0;
            margin: 0;
            list-style-type: none;
            li {
                a {
                    padding: 25px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    display: block;
                    width: 100%;
                    text-transform: uppercase;
                    font-family: 'Roboto Slab', sans-serif;
                    color: white;
                    transition: 50ms all ease-in;
                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: none;
                    }
                    .prod-group-image-container {
                        width: 85px;
                        height: 85px;
                        display: block;
                        float: left;
                        img {
                            width: 85px;
                            height: 85px;
                            border-radius: 2px;
                        }
                    }
                    .prodgroup-title {
                        float: left;
                        display: block;
                        padding-right: 0px;
                        padding-left: 15px;
                        padding-top: 34.5px;
                        @media screen and (max-width: 375px) {
                            max-width: 230px;
                        }
                    }
                    &:after {
                        content: "";
                        display: table;
                        clear: both;
                    }
                }
                &.active {
                    a {
                        background-color: #222;
                    }
                }
                &:after {
                    content: "";
                    display: table;
                    clear: both;
                }
            }
        }
        @media screen and (max-width: 991px) {
            display: block;
        }
    }
    &.open-side-nav {
        overflow-y: hidden;
        overflow: hidden;
        .mobile-side-nav {
            transform: translateX(0%);
        }
    }
}

.nav-categories {
    display: block;
    /*padding-top: 20px;*/
    .list-layout-title {
        margin: 0;
    }
    .nav-categories-list {
        display: block;
        padding: 0;
        margin: 0;
        list-style-type: none;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 0px;
        /*display: flex;*/
        /*flex-direction: row;*/
        /*justify-content: space-between;*/
        /*overflow: auto;*/
        li.menu-list-item {
            transition: 150ms all ease-in;
            position: relative;
            /*min-width: 8%;*/
            width: 100px;
            a {
                display: block;
                /*max-width: 100%;*/
                font-size: 10px;
                display: block;
                text-decoration: none;
                /*padding-left: 5px;
                padding-right: 5px;
                max-width: 100px;*/
                /*color: $white_1;*/
                &:hover,
                &:focus,
                &:active {
                    color: $theme_color;
                    text-decoration: none;
                }
                .categorie-nav-item-name {
                    /*max-width: 100px;*/
                    display: block;
                    text-align: center;
                }
                img {
                    display: block;
                    max-width: 95%;
                    margin: 0 auto;
                    float: none;
                    max-height: 60px; // max-width: 60px;
                }
            }
            &:hover {
                a {
                    /*font-weight: bolder;*/
                    color: $theme_color;
                }
                &:before {
                    left: 5px;
                    color: $theme_color;
                }
            }
            &.active {
                a {
                    /*font-weight: bolder;*/
                    color: $theme_color;
                }
                &:before {
                    left: 5px;
                    color: $theme_color;
                }
            }
        }
    }
}

.container-fluid {
    position: relative;
}

.default-inner-sides {
    padding-left: 15px;
    padding-right: 15px;
    &.inner-body {
        background-color: $grey;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.nav-categories-column {
    display: block;
    margin: 0 auto;
    max-width: 90vw;
    right: 0;
    left: 0;
    float: none;
    @media screen and (max-width: 991px) {
        .slick-slide {
            img {
                display: block;
                max-width: 100%;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    body {
        .wrapper {
            .order-wrapper {
                .block-layout {
                    .nav-categories-column {
                        position: relative;
                        margin-top: 12.5px;
                        .nav-categories {
                            /*margin-top: 12.5px;*/
                            /*background-color: #202020;*/
                            margin-left: -15px;
                            padding-left: 15px;
                            padding-right: 15px;
                            margin-right: -15px;
                            /*max-height: 50vh;*/
                            /*overflow-y: auto;*/
                        }
                    }
                    &.opencart {
                        .prod-grid {
                            width: 100%;
                        }
                    }
                }
            }
            #main_navbar {
                #navbar {
                    .nav-cart {
                        &.cart-active {
                            background-color: transparent;
                            .cart-href {
                                border-left-width: 0px;
                                background-color: transparent;
                                .order-info {
                                    color: $white_1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.products-wrapper {
    /*max-width: 60%;*/
    padding-top: 25px;
}

@media screen and (min-width: 1300px) {
    .cart-chevron-turn {
        display: none;
    }
    #main_navbar.stay-top {
        /*top: inherit;*/
        /*bottom: 0;*/
        /*position: absolute;*/
    }
    body {
        .wrapper {
            .order-wrapper {
                .block-layout {
                    .nav-categories-column {
                        margin-top: 25px;
                        .nav-categories {}
                    }
                    .prod-grid>div {
                        .product-list {
                            max-width: 615px;
                            max-width: 685px;
                            max-width: 900px;
                            @media screen and (min-width: 1399px) {
                                max-width: 1000px;
                            }
                            /*margin-left: 15px;*/
                            .product-tab {
                                .products-wrapper {
                                    /*max-width: 60%;*/
                                    /*padding-top: 25px;*/
                                }
                            }
                        }
                    }
                }
            }
            #main_navbar {
                #navbar {
                    .nav-cart {
                        >a {
                            &:hover {
                                .order-info {
                                    /*color: #000;*/
                                }
                            }
                        }
                        .order-info {
                            white-space: nowrap;
                            color: $white_1;
                        }
                        .cart-inner {
                            /*max-height: 70%;*/
                            border-top: 0px solid transparent;
                            .error-line {
                                background-color: #333;
                            }
                            .cart-line {
                                /*border-bottom: 1px dotted rgba(255, 255, 255, 0.25);*/
                                border-bottom: 1px dotted rgba(0, 0, 0, 0.15);
                            }
                            /*&::after {
                                content: '';
                                position: absolute;
                                height: calc(100vh - 250px);
                                width: 100%;
                                display: block;
                                background-color: #202020;
                                top: 0;
                                z-index: -1;
                            }*/
                        }
                        .price-total-container {
                            border-bottom: 2px solid rgba(255, 255, 255, 0.3);
                        }
                        .nav-cart-container {
                            height: calc(100vh - 300px);
                            /*height: auto !important;*/
                            /*overflow: inherit;*/
                            top: 210px;
                            /*padding-top: 5px;*/
                            /*display: none !important;*/
                            .nav-cart-container {
                                padding-top: 5px;
                            }
                        }
                    }
                    .nav-cart.cart-active {
                        .nav-cart-container {}
                    }
                }
            }
        }
        .footer {}
    }
}

.header.add-slider .slick-slide {
    height: auto !important;
}

.review-list .review_item .review_inner {
    background-color: $grey;
}

.lightboxOverlay {
    background-color: grey;
}

.lightbox {
    .lightbox-add-prod {
        margin-top: 10px;
        i {
            margin-left: 5px;
        }
    }
    .lb-dataContainer {
        position: relative;
        background-color: #fff;
        min-width: 300px;
        .lightbox-product-container {
            width: 100%;
            position: absolute;
            background-color: #eee;
            padding: 25px;
            top: 0;
            left: 0;
            right: 0;
            .lightbox-title {
                display: block;
                font-family: 'Roboto Slab', sans-serif;
                color: #000;
                font-size: 1.4em;
                font-weight: normal;
            }
            .lightbox-description {
                font-size: 1.2em;
                line-height: 1.2em;
                display: block;
                font-style: italic;
                color: #666;
                font-weight: normal;
                margin: 0;
            }
        }
        .lb-close {
            position: absolute;
            transform: scale(0.5);
            right: 5px;
            top: 5px;
            z-index: 10;
        }
    }
    .lb-outerContainer {
        min-width: 300px;
        background-color: #fff;
        .lb-image {
            border: 0px solid #fff;
            margin: 0 auto;
        }
    }
}

body a.full-overlay-link {
    background-color: transparent !important;
    color: transparent !important;
    border: none !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    height: 0px;
    width: 0px;
    padding: 0;
    margin: 0;
    min-width: 0 !important;
    min-height: 0 !important;
    line-height: 0 !important;
    display: block;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 2; // background-color: rgba(0,0,0,.4);
    }
}

.actie-prod-container {
    .actie-product {
        position: relative;
        &:after {
            display: block;
            content: 'Actie';
            top: -10px;
            right: -10px;
            color: #fff;
            position: absolute;
            padding: 2.5px 7.5px;
            border-radius: 2px;
            font-size: 16px;
            background-color: #ED1B23;
            z-index: 10;
            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
            font-family: "Roboto Slab", serif;
        }
        .cover-img {
            background-size: contain;
            background-repeat: no-repeat;
        }
        .product-info {
            display: block;
            background-color: $grey;
            padding: 15px;
            p {
                margin: 0;
                color: $black_1;
                text-decoration: none;
            }
            .product-description {
                max-height: none !important;
                min-height: 0px !important;
                overflow: auto;
                font-style: italic;
                color: $darkgrey;
            }
        }
    }
}

.lb-data .lb-caption a {
    color: #fff;
}

.open-side-nav-mobile.cat-tooltip:before {
    position: absolute;
    padding: 0px;
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    content: 'Kies een categorie!';
    display: block;
    top: 44px;
    left: 0;
    z-index: 1;
    color: #000;
    padding: 10px 18px;
}

.open-side-nav-mobile.cat-tooltip:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 15px 18px;
    border-color: #fff transparent;
    display: block;
    width: 0;
    z-index: 1;
    top: 28px;
    left: 27px;
}

.relative {
    position: relative;
}

.pre-footer {
    border-top: 25px solid #080001;
    margin-bottom: -25px;
    background-color: #101010;
    z-index: 6;
    position: relative;
    padding: 20px;
    padding-bottom: 30px;
    h2 {
        font-size: 25px;
    }
    p {
        font-weight: lighter;
        max-width: 1000px;
    }
}

.footer {
    .app-footer {
        display: none;
        @media screen and (max-width: 767px) {
            display: block;
        }
    }
    .social-footer {
        color: $theme_color !important;
    }
    .footer-logo {
        margin-top: 5px;
        background-color: #fff;
        border-radius: 3px;
        padding: 3px;
        float: left;
        margin-right: 3px;
        img {
            display: block;
            max-width: 100%;
            max-height: 25px;
        }
    }
    p.credit {
        text-align: right;
    }
    .footer-list {
        li {
            &:before {
                padding-top: .5px;
            }
            h3 {
                margin: 0;
                padding: 0;
                a {
                    font-size: 14px;
                }
            }
        }
    }
}

.home-menu-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: right;
    padding: 5px 10px;
    h1 {
        margin: 0;
        padding: 0;
        display: block;
        font-size: 12px;
        font-weight: bold;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.red-text {
    color: #ED1B23;
}

.ie-alert {
    position: fixed;
    display: block;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    text-align: center;
    padding: 4px;
    p {
        margin: 0 auto;
        color: #000;
        width: 1270px;
        max-width: 100%;
        a {
            font-weight: bolder;
            color: #000;
            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}

.sub-footer {
    margin-top: -1px;
    padding-top: 30px;
    padding-bottom: 30px;
    .footer-menu {
        padding: 0px;
        >li {
            margin-top: 5px;
            margin-bottom: 5px;
            a {
                color: #000;
                font-size: 1.2em;
            }
        }
    }
}

.header {
    .navbar-default {
        &.fixed-top {
            .nav-cart-container {
                top: 181px !important;
                @media screen and (max-width: 1299px) {
                    top: 50px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1299px) {
    .order-bottom-info {
        white-space: nowrap;
    }
    body .wrapper #main_navbar #navbar .nav-cart.cart-active .nav-cart-container {
        position: fixed;
        top: 50px;
        right: 0px !important;
    }
}

/*@media screen and (min-width: 1320px) {
    .page-bestel {
        .order-wrapper .block-layout .prod-grid {
            width: 70%;
        }
        .header {
            .navbar {
                .nav-cart {
                    .nav-cart-container {
                        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
                        height: 80%;
                        height: calc(100vh - 250px) !important;
                    }
                }
            }
            .navbar-default {
                .navbar-nav>li {
                    >a {
                        background-color: transparent;
                        color: #fff;
                        color: #f7f7f7 !important;
                    }
                }
                .cart-href {
                    background-color: $body_background !important;
                    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
                    border-left: 0px solid rgba(235, 235, 235, 0) !important;
                }
            }
            .nav-cart .order-info,
            .nav-cart .order-cart {
                color: #f7f7f7 !important;
            }
        }

        .block-layout {
            .prod-grid {
                width: 69%;
                float: left;
            }
        }
    }
}*/