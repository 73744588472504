.order-wrapper {
    .store-info {
        .store-inner {
            .store-image {
                @media screen and (min-width: 1000px) {
                    height: 200px;
                }
            }
        }
    }
}

.info-line {
    .btn {
        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
}

.actie-prod-container {
    background-color: #fff;
    border: 1px solid #eee;
}

.actie-prod-contact-seo {
    @media screen and (min-width: 768px) {
        display: block !important;
    }
}

.actie-prod-container .actie-product .cover-img {
    height: 150px;
    background-color: #fff;
    @media screen and (min-width: 767px) and (max-width: 1199px) {
        height: 80px;
    }
}

body .wrapper .header.small.contact-header {
    /*height: 400px;*/
}