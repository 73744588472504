@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700|Roboto+Slab|Roboto:400,700');

$body_background: #ffffff;
$black_1: #101010;
$black_2: #202020;
$black_3: #0A0A0A;
$black_4: #151414;
$white: #ffffff;
$white_1: #f7f7f7;
$white_2: #f0f0f0;
$grey: #d9d9d9;
$darkgrey: #333;

$theme_color: #eb5199;
$theme_color_darken: darken($theme_color, 12.5%);
$theme_sec_color: #ef5ea2;
$theme_sec_color_darken: darken($theme_sec_color, 12.5%);

$site_width: 100%;
$site_inner_witdh: 1430px;

* {
    font-family: 'Open Sans', sans-serif;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}